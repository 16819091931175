.root {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  background: #000000;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 0px;
}
.root > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .root > * {
    grid-column: 4;
  }
}
.section__mxbv {
  display: grid;
  position: relative;
  width: 100%;
  height: 72px;
  max-width: 100%;
  align-content: flex-start;
  justify-items: center;
  margin-bottom: 88px;
  background: #ffffff;
  margin-top: 31px;
  margin-left: 0px;
  min-width: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  border-radius: 10px;
  padding: 0px;
}
.section__mxbv > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__mxbv {
    padding-left: 0px;
    width: 100%;
    justify-self: flex-start;
    grid-column-start: 3 !important;
    grid-column-end: -3 !important;
    border-radius: 10px;
  }
}
@media (min-width: 768px) {
  .section__mxbv > * {
    grid-column: 4;
  }
}
.navigationBar:global(.__wab_instance) {
  width: 132.23%;
  display: flex;
  margin-bottom: 0px;
  max-width: 100%;
  object-fit: cover;
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 0px;
  height: 66px;
  padding: 16px;
}
@media (min-width: 768px) {
  .navigationBar:global(.__wab_instance) {
    width: 100%;
    grid-column-start: 3 !important;
    grid-column-end: -3 !important;
  }
}
.link__zgZ10 {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 35px;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.img__vo8Wv {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 35px;
  height: 35px;
  flex-shrink: 0;
}
.img__vo8Wv > picture > img {
  object-fit: cover;
}
.img__cQigJ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 15px;
  height: 35px;
  flex-shrink: 0;
}
.img__cQigJ > picture > img {
  object-fit: cover;
}
.img___07Ltf {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 115px;
  margin-right: 0px;
  height: 35px;
  flex-shrink: 0;
}
.img___07Ltf > picture > img {
  object-fit: cover;
}
.img___7DNfs {
  object-fit: cover;
}
.img___7DNfs > picture > img {
  object-fit: cover;
}
.img__pBpli {
  object-fit: cover;
}
.img__pBpli > picture > img {
  object-fit: cover;
}
.link__iE1F {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: var(--token-Le07ngBUPl2h);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  background: #d2d1d6;
  backdrop-filter: none;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition-property: all;
  transition-duration: 1s;
  margin-top: 10px;
  -webkit-backdrop-filter: none;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  border-radius: 10px;
  padding: 5px 15px;
}
@media (min-width: 768px) {
  .link__iE1F {
    padding-right: 15px;
    color: #000000d9;
    font-weight: 400;
    margin-top: 10px;
  }
}
.link__ghGwL {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  background: #d2d1d6;
  border-radius: 10px;
  padding: 5px 15px;
}
@media (min-width: 768px) {
  .link__ghGwL {
    padding-right: 15px;
    color: #000000d9;
    font-weight: 400;
    margin-top: 10px;
  }
}
.section__v4Lhk {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-top: 0px;
  justify-self: flex-start;
  margin-bottom: 35px;
  grid-column-start: 3 !important;
  grid-column-end: -3 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__v4Lhk > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__v4Lhk {
    width: 100%;
    justify-self: flex-start;
    margin-bottom: 74px;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .section__v4Lhk > * {
    grid-column: 4;
  }
}
.freeBox___6C2 {
  display: grid;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 0px;
}
@media (min-width: 768px) {
  .freeBox___6C2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 100%;
    justify-self: flex-start;
    grid-column-start: 1 !important;
    grid-column-end: -1 !important;
  }
}
.freeBox__nZYM {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 768px) {
  .freeBox__nZYM {
    width: auto;
  }
}
.img__raBRl {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  margin-top: 0px;
  height: 340px;
  flex-shrink: 0;
}
.img__raBRl > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__raBRl {
    height: 508px;
    width: 325px;
    margin-top: 64px;
    margin-bottom: 0px;
    flex-shrink: 0;
  }
}
.freeBox__xOhau {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__xOhau {
    align-items: center;
    justify-content: flex-start;
  }
}
.text__ktGAd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ff0000;
  font-size: 41px;
  font-weight: 800;
  font-family: "IBM Plex Mono";
  min-width: 0;
}
@media (min-width: 768px) {
  .text__ktGAd {
    margin-top: 119px;
  }
}
.text__hkexL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-bottom: 0px;
  min-width: 0;
}
.link__v6Q {
  position: relative;
  margin-top: 38px;
  display: flex;
}
.button2__x3UtD:global(.__wab_instance) {
  max-width: 100%;
}
.svg__u4P88 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___7EdFw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.section__llfDp {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-top: 0px;
  justify-self: flex-start;
  background: var(--token-jgohepLVeKvh);
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__llfDp > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__llfDp > * {
    grid-column: 4;
  }
}
.freeBox__i6Fw7 {
  display: grid;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-start: 3 !important;
  grid-column-end: -3 !important;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__i6Fw7 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 100%;
    grid-column-start: 3 !important;
    grid-column-end: -3 !important;
  }
}
.freeBox__iDm9N {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.lottie:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  border-radius: 10px;
}
.columns__u3Wdl {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 0px;
}
.columns__u3Wdl > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__u3Wdl > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 768px) {
  .columns__u3Wdl {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .columns__u3Wdl > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 768px) {
  .columns__u3Wdl > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
.column__oZKa {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px 4px 0px 0px;
}
.img__rVuIg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  border-radius: 10px;
}
.img__rVuIg > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__rVuIg {
    width: 351px;
  }
}
.column___5WSpv {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px 0px 0px 4px;
}
.img__ahWkV {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 417px;
  margin-left: 0px;
  border-radius: 10px;
}
.img__ahWkV > picture > img {
  object-fit: cover;
}
.freeBox__uGoKk {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.text__uyVp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 37px;
  font-weight: 700;
  text-align: center;
  font-family: "IBM Plex Mono";
  color: #ff0000;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__uyVp {
    font-size: 33px;
  }
}
.text__fGtLz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: justify;
  min-width: 0;
}
.link__xCpFg {
  position: relative;
  margin-top: 25px;
  display: flex;
}
.button2__lkO3D:global(.__wab_instance) {
  max-width: 100%;
}
.svg__xSnqf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__f6RJu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox___1QVgI {
  display: grid;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___1QVgI {
    width: 100%;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-start: 3 !important;
    grid-column-end: -3 !important;
  }
}
.freeBox__yIzAt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.text__vUuA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: left;
  font-size: 20px;
  font-family: "IBM Plex Mono";
  min-width: 0;
}
.text___8WXdX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 35px;
  text-align: justify;
  color: #ff0000;
  font-weight: 700;
  font-family: "IBM Plex Mono";
  min-width: 0;
}
.text__bvYq4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.columns__j83W4 {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__j83W4 > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__j83W4 > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 768px) {
  .columns__j83W4 {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .columns__j83W4 > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 768px) {
  .columns__j83W4 > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
.column__bGpKv {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.link__zKzJd {
  position: relative;
  display: flex;
}
.button2__kQgU:global(.__wab_instance) {
  max-width: 100%;
  width: 170px;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .button2__kQgU:global(.__wab_instance) {
    width: 250px;
    flex-shrink: 0;
  }
}
.svg__ikX1C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___0U3Ns {
  font-size: 10px;
}
@media (min-width: 768px) {
  .text___0U3Ns {
    font-size: 16px;
  }
}
.svg__t5PGo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.column__qJZg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.link__htapi {
  position: relative;
  display: flex;
}
.button2__zAvzT:global(.__wab_instance) {
  max-width: 100%;
}
.svg__sgJVj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__peDll {
  font-size: 10px;
}
@media (min-width: 768px) {
  .text__peDll {
    font-size: 16px;
  }
}
.svg__xGLy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__pwErR {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
  margin: 0px;
}
@media (min-width: 768px) {
  .freeBox__pwErR {
    display: flex;
    flex-direction: row;
  }
}
.freeBox__vv4N {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-LN1YkB123lXQ);
  margin-right: 10px;
  min-width: 0;
  border-radius: 20px;
  padding: 0px;
}
@media (min-width: 768px) {
  .freeBox__vv4N {
    border-radius: 10px;
  }
}
.img__zp46Z {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 180px;
  border-radius: 20px;
}
.img__zp46Z > picture > img {
  object-fit: cover;
}
.text__f8Vgm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 0px;
  min-width: 0;
}
.freeBox__lXGuo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-LN1YkB123lXQ);
  margin-right: 10px;
  min-width: 0;
  border-radius: 20px;
  padding: 0px;
}
@media (min-width: 768px) {
  .freeBox__lXGuo {
    border-radius: 10px;
  }
}
.img___6KcLx {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 180px;
  border-radius: 20px;
}
.img___6KcLx > picture > img {
  object-fit: cover;
}
.text__bcusO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 0px;
  min-width: 0;
}
.freeBox__txSjf {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-LN1YkB123lXQ);
  min-width: 0;
  border-radius: 20px;
  padding: 0px;
}
@media (min-width: 768px) {
  .freeBox__txSjf {
    border-radius: 10px;
  }
}
.img__dFvrW {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 180px;
  border-radius: 20px;
}
.img__dFvrW > picture > img {
  object-fit: cover;
}
.text___86JGf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 0px;
  min-width: 0;
}
.section__lSvwa {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0px;
  justify-self: flex-start;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__lSvwa > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__lSvwa {
    width: 100%;
    justify-self: flex-start;
    grid-column-start: 1 !important;
    grid-column-end: -1 !important;
  }
}
@media (min-width: 768px) {
  .section__lSvwa > * {
    grid-column: 4;
  }
}
.freeBox__jcdOb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 8px;
}
.img___1SEw7 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 638px;
  width: auto;
  flex-shrink: 0;
  border-bottom: 0px solid var(--token-jgohepLVeKvh);
}
.img___1SEw7 > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img___1SEw7 {
    width: 754px;
    height: 757px;
    border-bottom-width: 0px;
    flex-shrink: 0;
  }
}
.columns__qx4Fj {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
  border-top: 4px solid var(--token-jgohepLVeKvh);
}
.columns__qx4Fj > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 768px) {
  .columns__qx4Fj {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    border-top-color: var(--token-jgohepLVeKvh);
    border-top-style: solid;
    min-width: 0;
    border-width: 4px;
  }
}
@media (min-width: 768px) {
  .columns__qx4Fj > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 768px) {
  .columns__qx4Fj > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
.column__pckuq {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px;
  border-bottom: 4px solid var(--token-jgohepLVeKvh);
}
@media (min-width: 768px) {
  .column__pckuq {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
    padding-right: 0px;
    border: 0px solid transparent;
  }
}
.img__qHwFd {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  border: 0px solid var(--token-jgohepLVeKvh);
}
.img__qHwFd > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__qHwFd {
    border-width: 0px;
  }
}
.column__l0CXn {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 768px) {
  .column__l0CXn {
    border-left-color: var(--token-jgohepLVeKvh);
    border-left-style: solid;
    padding-left: 193px;
    border-width: 0px;
  }
}
.img___1XZji {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img___1XZji > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img___1XZji {
    border-left: 4px solid var(--token-jgohepLVeKvh);
  }
}
.section__kJbj {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  justify-self: flex-start;
  background: var(--token-jgohepLVeKvh);
  padding-top: 20px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__kJbj > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__kJbj > * {
    grid-column: 4;
  }
}
.img__yqj6T {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__yqj6T > picture > img {
  object-fit: cover;
}
.columns__xsXLf {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__xsXLf > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__xsXLf > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 768px) {
  .columns__xsXLf {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .columns__xsXLf > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 768px) {
  .columns__xsXLf > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
.column__b5Xb {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.link__jSk1J {
  position: relative;
  display: flex;
  max-width: 197px;
  width: 100%;
  min-width: 0;
}
.button2__tr4Ph:global(.__wab_instance) {
  max-width: 100%;
  width: 209px;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .button2__tr4Ph:global(.__wab_instance) {
    width: 341px;
    flex-shrink: 0;
  }
}
.svg__wTWcc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vFok {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.column__nt0Ty {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.link__dY0Mg {
  position: relative;
  display: flex;
}
.button2__cpesB:global(.__wab_instance) {
  max-width: 100%;
  width: 168px;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .button2__cpesB:global(.__wab_instance) {
    width: 342px;
    flex-shrink: 0;
  }
}
.svg__nodCe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cHhzE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.section__awcQo {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  justify-self: flex-start;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__awcQo > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__awcQo > * {
    grid-column: 4;
  }
}
.freeBox___2UNqE {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #000000;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___2UNqE {
    width: 100%;
    justify-self: flex-start;
    grid-column-start: 1 !important;
    grid-column-end: -1 !important;
  }
}
.text__w2Po9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 0.7em;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__w2Po9 {
    width: 100%;
    min-width: 0;
  }
}
.link__s0HO {
  position: relative;
  display: flex;
}
.svg__wms1I {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.link__dSmnq {
  position: relative;
  display: flex;
}
.svg__r9VD {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.link___2Dita {
  position: relative;
  display: flex;
}
.svg__yys9W {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.link___2FTve {
  position: relative;
  display: flex;
}
.svg___2VKdo {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.link__ruAek {
  position: relative;
  display: flex;
}
.svg__gtpK3 {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
